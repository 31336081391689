import { setCookie, getCookie, eraseCookie } from "../utility/cookie";

jQuery(window).on("load", function($) {
  console.log("The page has loaded.--");
  var maintenancecookie = getCookie("maintenancecookie");
  if (maintenancecookie == null) {
    setCookie("maintenancecookie", "true", 7);
  }
  var maintenancecookiecheck = getCookie("maintenancecookie");
  if (maintenancecookiecheck == "true") {
    jQuery(".maintenance-notification-wrapper").addClass("maintenance-active");
  }

});
