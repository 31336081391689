import { setCookie, getCookie, eraseCookie } from "../utility/cookie"

$(document).ready(function() {
  console.log("Theme scripts working")

  $(".hamburger").click(function() {
    $(this).toggleClass("is-active")
    $(".main-nav-wrapper").toggleClass("menu-open")
    $(".menu-overlay").toggleClass("menu-overlay-active")
  })
  $(".close-menu").click(function() {
    $(".main-nav-wrapper").toggleClass("menu-open")
    $(".hamburger").toggleClass("is-active")
    $(".menu-overlay").toggleClass("menu-overlay-active")
  })
  $(".menu-overlay").click(function() {
    $(".main-nav-wrapper").toggleClass("menu-open")
    $(".hamburger").toggleClass("is-active")
    $(".menu-overlay").toggleClass("menu-overlay-active")
  })
  $(".menu > .menu-item-has-children > a").click(function(e) {
    if (
      !$(this)
        .parents("li")
        .hasClass("sub-menu-open")
    ) {
      e.preventDefault()
      $(this)
        .parents("li")
        .addClass("sub-menu-open")
    }
  })
  $(".arrow-up").click(function() {
    $(this)
      .parent("li.sub-menu-open")
      .removeClass("sub-menu-open")
  })
  $(".arrow-down").click(function() {
    $(this)
      .parent("li")
      .addClass("sub-menu-open")
  })

  $(".category-select").change(function() {
    var selectValue = $(this)
      .find(":selected")
      .val()
    if (selectValue == "blog") {
      window.location.href = "/blog/"
    } else {
      window.location.href = "/category/" + selectValue
    }
  })

  // REMOVING BOTTOM BORDER OF THE POST-REPEATER-WITH-PAGINATION BLOCK //
  $(".category-post-border-bottom")
    .last()
    .css("display", "none")
  // REMOVING BOTTOM BORDER OF THE PERSON-REPEATER BLOCK //
  $(".person-category-bottom-border")
    .last()
    .css("display", "none")

  $(".search-icon").click(function() {
    $(".search-replace-wrapper").toggleClass("active")
    $(".parent1").on("transitionend", function(e) {
      $("#searchsubmit").addClass("move-right")
      $(".search-icon").css({
        opacity: "0",
        transition: "none",
      })
      $(".email-icon").css({
        opacity: "0",
        transition: "none",
      })
      $(".sign-in-link").css({
        opacity: "0",
        transition: "none",
      })
      $(".careers-link").css({
        opacity: "0",
        transition: "none",
      })
      $(".support-link").css({
        opacity: "0",
        transition: "none",
      })
    })
  })
  $(".close-search").click(function() {
    $(".search-replace-wrapper").toggleClass("active")
    $(".parent1").on("transitionend", function(e) {
      $("#searchsubmit").removeClass("move-right")
      $(".search-icon").css({
        opacity: "1",
        transition: "opacity .3s linear",
      })
      $(".email-icon").css({
        opacity: "1",
        transition: "opacity .3s linear",
      })
      $(".sign-in-link").css({
        opacity: "1",
        transition: "opacity .3s linear",
      })
      $(".careers-link").css({
        opacity: "1",
        transition: "opacity .3s linear",
      })
      $(".support-link").css({
        opacity: "1",
        transition: "opacity .3s linear",
      })
    })
  })

  $(".maintenance-notification-close").click(function() {
    $(".maintenance-notification-wrapper").fadeOut(function() {
      setCookie("maintenancecookie", "false", 7)
    })
  })

  //Search for URL parameter to anchor link to Online Scheduling form
  function hasURLParameter(name) {
    return location.search.indexOf(name + "=") !== -1
  }
  if (hasURLParameter("sch")) {
    let targetElement = document.getElementById(
      "form_onlineschedulinglandingpageform22"
    )
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" })
    }
  }
})
