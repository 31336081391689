/*******************
 * Script Imports
 *******************/
import $ from "jquery";
window.$ = window.jQuery = $;

import "popper.js";
import "bootstrap";
import "slick-slider";
import "./blocks/image-slider/image-slider";
import "./blocks/vue/vue";
import "./blocks/tabs/tabs";
import "./blocks/map/map";

// import "./vendor/olark";
import "./utility/detect-ie";
import "./utility/is-touch-device";
import "./utility/acf-google-map";
import "./../node_modules/bootstrap-table/dist/bootstrap-table.js";
import "./theme/on-load.js";
import "./theme/on-scroll.js";
import "./theme/on-resize.js";
import "./vendor/vendor";
import "./theme/scripts";

/*******************
 * Style Imports
 *******************/
import "../scss/main.scss";
